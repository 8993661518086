
@font-face {
  font-family: 'Montserrat';
  src:  url('../src/styles/fonts/Montserrat-Bold.ttf') format('ttf'),
        url('../src/styles/fonts/Montserrat-Regular.ttf') format('ttf');
}

body{
  font-size: 15px;
  font-family: 'Montserrat',sans-serif;
}

a {
    color: var(--primary-color);
    text-decoration: none;
  }
  
  a:hover {
    color: var(--primary-color);
  }
  
  ul{
    &.no-styling{
      list-style: none;
    }
  }
  
  img {
    width: 100%;
  }

  a.email{
    text-decoration: underline;
    &:hover{
      color: #AFB3F4;
    }
  }

  
  
  .logo{
    width: 250px;
    height: 45px;
  }

  .logo-footer{
    width: 45px;
    height: 40px;
  }

  .h1{
    font-size: 0;
  }
  
  .footer {
    display: flex;
    justify-content: end;
    padding: 0.7rem 2rem;
    margin-top:0;
    //opacity: 0.9;
    background-color: #0E1031;
    border-top: white 1px solid;
  }
  
  .navbar {
    .nav-link{

      span {
        display: block;
        //line-height: 1.3;
        border-bottom: transparent solid 2px;
      }
      &.active{
        span{
        border-color: #000000;
        }
      }
    }
    &.text-white{
      .nav-link.active{
        span{
          border-color: #ffffff;
        }
        
      }

    }
    
    &>.container{
      flex-direction: column;
      .navbar-nav{
        width: 100%;
      }
      @media only screen and (min-width: 768px) {
        flex-direction: row;
      }
    }

    .navbar-toggler{
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }


  .footer .active {
    font-weight: bold;
  }

  
  .card-session{
    background-color: #EEEFFF;
  }

  .border-bottom{
    border-bottom: 1px solid #dddddd;

    &-bold{
      border-bottom: 1px solid #000000;  
    }
  }



  .btn{

    border-radius: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    border: 1px solid rgba(black,.3);

    &.btn-primary{
      border-radius: 0;
      transition: all ease-in-out .3s;
      &:hover{
        background-color: #0E1031;
        color: white;
        transition: all ease-in-out .3s;
      }
      &.btn-active{
        background-color: #FFEF00;
      }
    }

    &.disabled{
      color:inherit;

      background-color: transparent;
      border: 1px solid #000000;  
      opacity: .4;
    }

    &.btn-yellow{
      border-color: transparent;
      background-color: #FFEF00;
      &:hover{
          background-color: #0E1031;
          color: white;
          transition: all ease-in-out .3s;
      }
    }

    &.btn-edit{    
      vertical-align: bottom;
      border: none;
      width: 1.5rem;
      height: 1.5rem;
      text-indent: -999999px;
      background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.6493 3.5858C35.8739 3.81912 36 4.13513 36 4.46458C36 4.79403 35.8739 5.11004 35.6493 5.34336L33.142 7.94236L28.3341 2.96344L30.8414 0.36444C31.0668 0.131089 31.3725 0 31.6912 0C32.0099 0 32.3156 0.131089 32.541 0.36444L35.6493 3.58331V3.5858ZM31.4424 9.69992L26.6346 4.721L10.2566 21.6842C10.1243 21.8212 10.0247 21.9882 9.96573 22.1721L8.03057 28.1817C7.99547 28.2912 7.99049 28.4087 8.01618 28.5211C8.04186 28.6334 8.09721 28.7361 8.17602 28.8177C8.25484 28.8993 8.35402 28.9566 8.46248 28.9832C8.57095 29.0098 8.68442 29.0047 8.79021 28.9683L14.5933 26.9643C14.7706 26.904 14.9319 26.8017 15.0645 26.6656L31.4424 9.69992Z' fill='%23AFB3F4'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 32.25C0 33.2446 0.395088 34.1984 1.09835 34.9016C1.80161 35.6049 2.75544 36 3.75 36H31.25C32.2446 36 33.1984 35.6049 33.9016 34.9016C34.6049 34.1984 35 33.2446 35 32.25V17.25C35 16.9185 34.8683 16.6005 34.6339 16.3661C34.3995 16.1317 34.0815 16 33.75 16C33.4185 16 33.1005 16.1317 32.8661 16.3661C32.6317 16.6005 32.5 16.9185 32.5 17.25V32.25C32.5 32.5815 32.3683 32.8995 32.1339 33.1339C31.8995 33.3683 31.5815 33.5 31.25 33.5H3.75C3.41848 33.5 3.10054 33.3683 2.86612 33.1339C2.6317 32.8995 2.5 32.5815 2.5 32.25V4.75C2.5 4.41848 2.6317 4.10054 2.86612 3.86612C3.10054 3.6317 3.41848 3.5 3.75 3.5H20C20.3315 3.5 20.6495 3.3683 20.8839 3.13388C21.1183 2.89946 21.25 2.58152 21.25 2.25C21.25 1.91848 21.1183 1.60054 20.8839 1.36612C20.6495 1.1317 20.3315 1 20 1H3.75C2.75544 1 1.80161 1.39509 1.09835 2.09835C0.395088 2.80161 0 3.75544 0 4.75V32.25Z' fill='%23AFB3F4'/%3E%3C/svg%3E");
      background-size: cover;

      &:hover{

          background-image: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.6493 3.5858C35.8739 3.81912 36 4.13513 36 4.46458C36 4.79403 35.8739 5.11004 35.6493 5.34336L33.142 7.94236L28.3341 2.96344L30.8414 0.36444C31.0668 0.131089 31.3725 0 31.6912 0C32.0099 0 32.3156 0.131089 32.541 0.36444L35.6493 3.58331V3.5858ZM31.4424 9.69992L26.6346 4.721L10.2566 21.6842C10.1243 21.8212 10.0247 21.9882 9.96573 22.1721L8.03057 28.1817C7.99547 28.2912 7.99049 28.4087 8.01618 28.5211C8.04186 28.6334 8.09721 28.7361 8.17602 28.8177C8.25484 28.8993 8.35402 28.9566 8.46248 28.9832C8.57095 29.0098 8.68442 29.0047 8.79021 28.9683L14.5933 26.9643C14.7706 26.904 14.9319 26.8017 15.0645 26.6656L31.4424 9.69992Z' fill='%230E1031'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 32.25C0 33.2446 0.395088 34.1984 1.09835 34.9016C1.80161 35.6049 2.75544 36 3.75 36H31.25C32.2446 36 33.1984 35.6049 33.9016 34.9016C34.6049 34.1984 35 33.2446 35 32.25V17.25C35 16.9185 34.8683 16.6005 34.6339 16.3661C34.3995 16.1317 34.0815 16 33.75 16C33.4185 16 33.1005 16.1317 32.8661 16.3661C32.6317 16.6005 32.5 16.9185 32.5 17.25V32.25C32.5 32.5815 32.3683 32.8995 32.1339 33.1339C31.8995 33.3683 31.5815 33.5 31.25 33.5H3.75C3.41848 33.5 3.10054 33.3683 2.86612 33.1339C2.6317 32.8995 2.5 32.5815 2.5 32.25V4.75C2.5 4.41848 2.6317 4.10054 2.86612 3.86612C3.10054 3.6317 3.41848 3.5 3.75 3.5H20C20.3315 3.5 20.6495 3.3683 20.8839 3.13388C21.1183 2.89946 21.25 2.58152 21.25 2.25C21.25 1.91848 21.1183 1.60054 20.8839 1.36612C20.6495 1.1317 20.3315 1 20 1H3.75C2.75544 1 1.80161 1.39509 1.09835 2.09835C0.395088 2.80161 0 3.75544 0 4.75V32.25Z' fill='%230E1031'/%3E%3C/svg%3E");

      }
    }

    &.btn-edit-inline{
      vertical-align: top;
      border: none;
      width: 1rem;
      height: 1rem;
      
      text-indent: -999999px;
      //background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.6493 3.5858C35.8739 3.81912 36 4.13513 36 4.46458C36 4.79403 35.8739 5.11004 35.6493 5.34336L33.142 7.94236L28.3341 2.96344L30.8414 0.36444C31.0668 0.131089 31.3725 0 31.6912 0C32.0099 0 32.3156 0.131089 32.541 0.36444L35.6493 3.58331V3.5858ZM31.4424 9.69992L26.6346 4.721L10.2566 21.6842C10.1243 21.8212 10.0247 21.9882 9.96573 22.1721L8.03057 28.1817C7.99547 28.2912 7.99049 28.4087 8.01618 28.5211C8.04186 28.6334 8.09721 28.7361 8.17602 28.8177C8.25484 28.8993 8.35402 28.9566 8.46248 28.9832C8.57095 29.0098 8.68442 29.0047 8.79021 28.9683L14.5933 26.9643C14.7706 26.904 14.9319 26.8017 15.0645 26.6656L31.4424 9.69992Z' fill='%23AFB3F4'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 32.25C0 33.2446 0.395088 34.1984 1.09835 34.9016C1.80161 35.6049 2.75544 36 3.75 36H31.25C32.2446 36 33.1984 35.6049 33.9016 34.9016C34.6049 34.1984 35 33.2446 35 32.25V17.25C35 16.9185 34.8683 16.6005 34.6339 16.3661C34.3995 16.1317 34.0815 16 33.75 16C33.4185 16 33.1005 16.1317 32.8661 16.3661C32.6317 16.6005 32.5 16.9185 32.5 17.25V32.25C32.5 32.5815 32.3683 32.8995 32.1339 33.1339C31.8995 33.3683 31.5815 33.5 31.25 33.5H3.75C3.41848 33.5 3.10054 33.3683 2.86612 33.1339C2.6317 32.8995 2.5 32.5815 2.5 32.25V4.75C2.5 4.41848 2.6317 4.10054 2.86612 3.86612C3.10054 3.6317 3.41848 3.5 3.75 3.5H20C20.3315 3.5 20.6495 3.3683 20.8839 3.13388C21.1183 2.89946 21.25 2.58152 21.25 2.25C21.25 1.91848 21.1183 1.60054 20.8839 1.36612C20.6495 1.1317 20.3315 1 20 1H3.75C2.75544 1 1.80161 1.39509 1.09835 2.09835C0.395088 2.80161 0 3.75544 0 4.75V32.25Z' fill='%23AFB3F4'/%3E%3C/svg%3E");
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.6493 3.5858C35.8739 3.81912 36 4.13513 36 4.46458C36 4.79403 35.8739 5.11004 35.6493 5.34336L33.142 7.94236L28.3341 2.96344L30.8414 0.36444C31.0668 0.131089 31.3725 0 31.6912 0C32.0099 0 32.3156 0.131089 32.541 0.36444L35.6493 3.58331V3.5858ZM31.4424 9.69992L26.6346 4.721L10.2566 21.6842C10.1243 21.8212 10.0247 21.9882 9.96573 22.1721L8.03057 28.1817C7.99547 28.2912 7.99049 28.4087 8.01618 28.5211C8.04186 28.6334 8.09721 28.7361 8.17602 28.8177C8.25484 28.8993 8.35402 28.9566 8.46248 28.9832C8.57095 29.0098 8.68442 29.0047 8.79021 28.9683L14.5933 26.9643C14.7706 26.904 14.9319 26.8017 15.0645 26.6656L31.4424 9.69992Z' fill='%230E1031'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 32.25C0 33.2446 0.395088 34.1984 1.09835 34.9016C1.80161 35.6049 2.75544 36 3.75 36H31.25C32.2446 36 33.1984 35.6049 33.9016 34.9016C34.6049 34.1984 35 33.2446 35 32.25V17.25C35 16.9185 34.8683 16.6005 34.6339 16.3661C34.3995 16.1317 34.0815 16 33.75 16C33.4185 16 33.1005 16.1317 32.8661 16.3661C32.6317 16.6005 32.5 16.9185 32.5 17.25V32.25C32.5 32.5815 32.3683 32.8995 32.1339 33.1339C31.8995 33.3683 31.5815 33.5 31.25 33.5H3.75C3.41848 33.5 3.10054 33.3683 2.86612 33.1339C2.6317 32.8995 2.5 32.5815 2.5 32.25V4.75C2.5 4.41848 2.6317 4.10054 2.86612 3.86612C3.10054 3.6317 3.41848 3.5 3.75 3.5H20C20.3315 3.5 20.6495 3.3683 20.8839 3.13388C21.1183 2.89946 21.25 2.58152 21.25 2.25C21.25 1.91848 21.1183 1.60054 20.8839 1.36612C20.6495 1.1317 20.3315 1 20 1H3.75C2.75544 1 1.80161 1.39509 1.09835 2.09835C0.395088 2.80161 0 3.75544 0 4.75V32.25Z' fill='%230E1031'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      margin-top: 12px;

      &:hover{

        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.6493 3.5858C35.8739 3.81912 36 4.13513 36 4.46458C36 4.79403 35.8739 5.11004 35.6493 5.34336L33.142 7.94236L28.3341 2.96344L30.8414 0.36444C31.0668 0.131089 31.3725 0 31.6912 0C32.0099 0 32.3156 0.131089 32.541 0.36444L35.6493 3.58331V3.5858ZM31.4424 9.69992L26.6346 4.721L10.2566 21.6842C10.1243 21.8212 10.0247 21.9882 9.96573 22.1721L8.03057 28.1817C7.99547 28.2912 7.99049 28.4087 8.01618 28.5211C8.04186 28.6334 8.09721 28.7361 8.17602 28.8177C8.25484 28.8993 8.35402 28.9566 8.46248 28.9832C8.57095 29.0098 8.68442 29.0047 8.79021 28.9683L14.5933 26.9643C14.7706 26.904 14.9319 26.8017 15.0645 26.6656L31.4424 9.69992Z' fill='%23ffffff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 32.25C0 33.2446 0.395088 34.1984 1.09835 34.9016C1.80161 35.6049 2.75544 36 3.75 36H31.25C32.2446 36 33.1984 35.6049 33.9016 34.9016C34.6049 34.1984 35 33.2446 35 32.25V17.25C35 16.9185 34.8683 16.6005 34.6339 16.3661C34.3995 16.1317 34.0815 16 33.75 16C33.4185 16 33.1005 16.1317 32.8661 16.3661C32.6317 16.6005 32.5 16.9185 32.5 17.25V32.25C32.5 32.5815 32.3683 32.8995 32.1339 33.1339C31.8995 33.3683 31.5815 33.5 31.25 33.5H3.75C3.41848 33.5 3.10054 33.3683 2.86612 33.1339C2.6317 32.8995 2.5 32.5815 2.5 32.25V4.75C2.5 4.41848 2.6317 4.10054 2.86612 3.86612C3.10054 3.6317 3.41848 3.5 3.75 3.5H20C20.3315 3.5 20.6495 3.3683 20.8839 3.13388C21.1183 2.89946 21.25 2.58152 21.25 2.25C21.25 1.91848 21.1183 1.60054 20.8839 1.36612C20.6495 1.1317 20.3315 1 20 1H3.75C2.75544 1 1.80161 1.39509 1.09835 2.09835C0.395088 2.80161 0 3.75544 0 4.75V32.25Z' fill='%23ffffff'/%3E%3C/svg%3E");

      }
    }

    &.btn-del{    
      vertical-align: bottom;
      border: none;
      width: 1.5rem;
      height: 1.5rem;
      text-indent: -999999px;
      background-image: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 9.7782H9.77778H24" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M22.2222 9.77821V22.2227C22.2222 22.6942 22.0349 23.1463 21.7015 23.4797C21.3681 23.8131 20.9159 24.0004 20.4444 24.0004H11.5555C11.0841 24.0004 10.6319 23.8131 10.2985 23.4797C9.96507 23.1463 9.77777 22.6942 9.77777 22.2227V9.77821M12.4444 9.77821V8.00043C12.4444 7.52894 12.6317 7.07675 12.9651 6.74336C13.2985 6.40996 13.7507 6.22266 14.2222 6.22266H17.7778C18.2493 6.22266 18.7015 6.40996 19.0348 6.74336C19.3682 7.07675 19.5555 7.52894 19.5555 8.00043V9.77821" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.2222 14.2227V19.556" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.7778 14.2227V19.556" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      background-size: cover;
  
      &:hover{
        background-image: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 9.7782H9.77778H24" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M22.2222 9.77821V22.2227C22.2222 22.6942 22.0349 23.1463 21.7015 23.4797C21.3681 23.8131 20.9159 24.0004 20.4444 24.0004H11.5555C11.0841 24.0004 10.6319 23.8131 10.2985 23.4797C9.96507 23.1463 9.77777 22.6942 9.77777 22.2227V9.77821M12.4444 9.77821V8.00043C12.4444 7.52894 12.6317 7.07675 12.9651 6.74336C13.2985 6.40996 13.7507 6.22266 14.2222 6.22266H17.7778C18.2493 6.22266 18.7015 6.40996 19.0348 6.74336C19.3682 7.07675 19.5555 7.52894 19.5555 8.00043V9.77821" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.2222 14.2227V19.556" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.7778 14.2227V19.556" stroke="white" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        background-size: cover;
      }
      &--alt{
        &:hover{
          background-image: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="16" cy="16" r="16" fill="%23AFB3F4"/><path d="M8 9.7782H9.77778H24" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M22.2222 9.77821V22.2227C22.2222 22.6942 22.0349 23.1463 21.7015 23.4797C21.3681 23.8131 20.9159 24.0004 20.4444 24.0004H11.5555C11.0841 24.0004 10.6319 23.8131 10.2985 23.4797C9.96507 23.1463 9.77777 22.6942 9.77777 22.2227V9.77821M12.4444 9.77821V8.00043C12.4444 7.52894 12.6317 7.07675 12.9651 6.74336C13.2985 6.40996 13.7507 6.22266 14.2222 6.22266H17.7778C18.2493 6.22266 18.7015 6.40996 19.0348 6.74336C19.3682 7.07675 19.5555 7.52894 19.5555 8.00043V9.77821" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.2222 14.2227V19.556" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.7778 14.2227V19.556" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg>');
          background-size: cover;
        }
      }
    }

    &.btn-expand{
      vertical-align: top;
      border: none;
      width: 1.5rem;
      height: 1.5rem;
      text-indent: -999999px;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="%23000000" height="32" width="32" version="1.1" id="Capa_1" viewBox="0 0 469 469" xml:space="preserve"><g><g><path d="M455.5,0h-442C6,0,0,6,0,13.5v211.9c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V27h415v415H242.4c-7.5,0-13.5,6-13.5,13.5 s6,13.5,13.5,13.5h213.1c7.5,0,13.5-6,13.5-13.5v-442C469,6,463,0,455.5,0z"/><path d="M175.6,279.9H13.5c-7.5,0-13.5,6-13.5,13.5v162.1C0,463,6,469,13.5,469h162.1c7.5,0,13.5-6,13.5-13.5V293.4 C189.1,286,183,279.9,175.6,279.9z M162.1,442H27V306.9h135.1V442z"/><path d="M360.4,127.7v71.5c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V95.1c0-7.5-6-13.5-13.5-13.5H269.8c-7.5,0-13.5,6-13.5,13.5 s6,13.5,13.5,13.5h71.5L212.5,237.4c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4L360.4,127.7z"/></g></g></svg>');
      background-size: cover;
  
      &:hover{
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="%23000000" height="800px" width="800px" version="1.1" id="Capa_1" viewBox="0 0 469 469" xml:space="preserve"><g><g><path d="M455.5,0h-442C6,0,0,6,0,13.5v211.9c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V27h415v415H242.4c-7.5,0-13.5,6-13.5,13.5 s6,13.5,13.5,13.5h213.1c7.5,0,13.5-6,13.5-13.5v-442C469,6,463,0,455.5,0z"/><path d="M175.6,279.9H13.5c-7.5,0-13.5,6-13.5,13.5v162.1C0,463,6,469,13.5,469h162.1c7.5,0,13.5-6,13.5-13.5V293.4 C189.1,286,183,279.9,175.6,279.9z M162.1,442H27V306.9h135.1V442z"/><path d="M360.4,127.7v71.5c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V95.1c0-7.5-6-13.5-13.5-13.5H269.8c-7.5,0-13.5,6-13.5,13.5 s6,13.5,13.5,13.5h71.5L212.5,237.4c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4L360.4,127.7z"/></g></g></svg>');

        background-size: cover;
      }
      &--alt{
        &:hover{
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="%23000000" height="800px" width="800px" version="1.1" id="Capa_1" viewBox="0 0 469 469" xml:space="preserve"><g><g><path d="M455.5,0h-442C6,0,0,6,0,13.5v211.9c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V27h415v415H242.4c-7.5,0-13.5,6-13.5,13.5 s6,13.5,13.5,13.5h213.1c7.5,0,13.5-6,13.5-13.5v-442C469,6,463,0,455.5,0z"/><path d="M175.6,279.9H13.5c-7.5,0-13.5,6-13.5,13.5v162.1C0,463,6,469,13.5,469h162.1c7.5,0,13.5-6,13.5-13.5V293.4 C189.1,286,183,279.9,175.6,279.9z M162.1,442H27V306.9h135.1V442z"/><path d="M360.4,127.7v71.5c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V95.1c0-7.5-6-13.5-13.5-13.5H269.8c-7.5,0-13.5,6-13.5,13.5 s6,13.5,13.5,13.5h71.5L212.5,237.4c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4L360.4,127.7z"/></g></g></svg>');

          background-size: cover;
        }
      }
    }

    &.btn-close{
      vertical-align: bottom;
      border: none;
      width: 1rem;
      height: 1rem;
      text-indent: -999999px;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px"><path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"/></svg>');

      background-size: cover;
      opacity: 1;
  
      &:hover{
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px"><path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"/></svg>');
        background-size: cover;
      }
      &--alt{
        &:hover{
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px"><path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"/></svg>');
          background-size: cover;
        }
      }
    }

    &.btn-star{    
      vertical-align: bottom;
      border: none;
      width: 1.5rem;
      height: 1.5rem;
      text-indent: -999999px;
      background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="iso-8859-1"%3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 47.94 47.94" xml:space="preserve"><path style="fill:%23ED8A19;" d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956C22.602,0.567,25.338,0.567,26.285,2.486z"/></svg>');
      background-size: cover;
  
      &:hover{
        background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="iso-8859-1"%3F><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --><svg height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 47.94 47.94" xml:space="preserve"><path style="fill:%23ED8A19;" d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956C22.602,0.567,25.338,0.567,26.285,2.486z"/></svg>');

        background-size: cover;
      }
    }
 

    &.btn-view{
      vertical-align: bottom;
      border: none;
      width: 2rem;
      height: 1.8rem;
      text-indent: -999999px;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Uploaded to: SVG Repo  www.svgrepo.com  Generator: SVG Repo Mixer Tools --%3E%3Csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='3.5' stroke='%23AFB3F4'/%3E%3Cpath d='M20.188 10.9343C20.5762 11.4056 20.7703 11.6412 20.7703 12C20.7703 12.3588 20.5762 12.5944 20.188 13.0657C18.7679 14.7899 15.6357 18 12 18C8.36427 18 5.23206 14.7899 3.81197 13.0657C3.42381 12.5944 3.22973 12.3588 3.22973 12C3.22973 11.6412 3.42381 11.4056 3.81197 10.9343C5.23206 9.21014 8.36427 6 12 6C15.6357 6 18.7679 9.21014 20.188 10.9343Z' stroke='%23AFB3F4'/%3E%3C/svg%3E");

      background-size: cover;
      &:hover{
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Uploaded to: SVG Repo  www.svgrepo.com  Generator: SVG Repo Mixer Tools --%3E%3Csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='3.5' stroke='%23BFC3FF'/%3E%3Cpath d='M20.188 10.9343C20.5762 11.4056 20.7703 11.6412 20.7703 12C20.7703 12.3588 20.5762 12.5944 20.188 13.0657C18.7679 14.7899 15.6357 18 12 18C8.36427 18 5.23206 14.7899 3.81197 13.0657C3.42381 12.5944 3.22973 12.3588 3.22973 12C3.22973 11.6412 3.42381 11.4056 3.81197 10.9343C5.23206 9.21014 8.36427 6 12 6C15.6357 6 18.7679 9.21014 20.188 10.9343Z' stroke='%23BFC3FF'/%3E%3C/svg%3E");
        background-size: cover;
      }
    }

    &.btn-arrow{
      padding: 0;
      transition: all .3s ease-in-out;
      &::after{
        vertical-align: top;
        margin: 0 .5em;
        display: inline-block;
        content: '';
        width: 1.5em;
        height: 1.5em;
        background-image: url("data:image/svg+xml,%3Csvg width='26' height='23' viewBox='0 0 26 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.0159 0.580878L13.4079 2.18892L21.5131 10.2942L0.140903 10.2942L0.134139 12.5691L21.5225 12.5717L13.411 20.6832L15.0165 22.2887L25.8701 11.435L15.0159 0.580878Z' fill='white'/%3E%3C/svg%3E");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: all .3s ease-in-out;
     } 
     &:hover{
      opacity: .8;
      transition: all .3s ease-in-out;
      &::after{
        margin-left: 1em;
        opacity: .8;
        transition: all .3s ease-in-out;
      }
     }
    }

  }


 
.cta{
  position: relative;
  &::after{
      content: '';
      position: absolute;
      bottom: 1.5rem;
      right: 1.5rem;
      width: 1rem;
      height: 1rem;
      background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.64991 0V2.27411H15.1124L0 17.3866L1.60387 19L16.7296 3.87799V15.3494H19V0H3.64991Z' fill='black'/%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
  }
}



.login-input{
  border: none;
  border-radius: 0;
  border-bottom: black 1px solid;
  padding-left: .1rem;
}
.login-button{

  text-align: center;
}



.address{
  font-size: 12px;
}

.sticky-row{
  position: sticky;
  top: 0;
}


.editor-class{
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}

.z-index-0{
  position: relative;
  z-index: 0;
}

.cursor-pointer{
  cursor: pointer;
}

.row-border{

  &::after{
      content: '';
      display: block;
      width: 100%;
      border-bottom: solid 1px black;
  }
  &:last-of-type::after{
      border-bottom: none;
  }
}


.form-switch .form-check-input{
  border: none;
  background-color: #D9D9D9;
  &:checked{
    background-color: #AFB3F4;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%230E1031%27/%3e%3c/svg%3e")
  }
}



// Table rows

.session-row, .table-row{
    
  .btn{
   visibility: hidden;       
   //top:5px;
  }
  .edit-btns{
      height: 100%;
      width: 100px;
      position: absolute;
      top:0;
      right: 0;
  }

  &::before{
      content: '';
      z-index: -1;
      position: absolute;
      top:0;
      bottom:0;
      left:.75rem;
      right:.75rem;
  }


  &:hover{
      &::before{
          background-color: #AFB3F4;
      }
      .btn{
          visibility: visible;
      }
  }
}


input:disabled ~ .locked{
  background: green;
}