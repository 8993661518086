.aspect-ratio{
    aspect-ratio: 16/9;
    object-fit: cover;
}

.image-item{
    cursor: pointer;
    .btn.btn-del{
        border-radius: 50%;
        background-color: #AFB3F4;
        z-index: 1;
        top:5px;
        right:5px;
        opacity: 0;
    }
    &:hover{
        .btn.btn-del{
        opacity: 1;
        transition: opacity .3s smooth;
        }
    }

}