.bg{
    &-blue{
        background-color: #EEEFFF;
        //background-color: #0E1031;
    }
    &-yellow{
        background-color: #FFEF00;
    }
    &-yellow-alt{
        background-color: rgba(#FFEF00,.5);
    }
    &-blue-alt{
        background-color: rgba(#AFB3F4,.35);
    }
    &-grey{
        background-color: #888888;
    }
    &-grey-alt{
        background-color: #dddddd;
    }
}

.text{
    &-white{
        color: white;
    }
}

.not-selected{
    border: transparent 5px solid;
}

.selected{
    border: rgba(blue,.5) 5px solid;
}

.login-h2{
    font-size: 3.1rem;
    @media only screen and (min-width: 678px) {
        font-size: 2.5rem;
    }
    @media only screen and (min-width: 1200px) {
        font-size: 3.1rem;
    }
}

.login-background {
    background-image: url('../img/login-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top:0;
    right:0;
    width: 55vw;
    height: 100vh;
    @media only screen and (min-width: 1200px) {
        width: 50vw;
    }
}