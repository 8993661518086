/* .item must be first */
.item {
    align-items: center;

    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;
  }
  
  .disabled-page {
    cursor:auto;
    pointer-events: none;
    color: #808e9b;
  }
  
  .pagination-page{
    position: relative;

    a{
      &::after{
        content: '';
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .pagination-page{
      &.active {
        //border: solid 1px #808e9b;
        //border-radius: 40px;
        //color: white;
        background-color: #FFEF00;
      }
      &:hover{
        text-decoration: underline;
      }

  }
  
  
  
  .next,.previous {
    //border-left: solid 1px #808e9b;
    font-size: 4px;
    height: 20px;
    position: relative;
    //right: 0;
    width: 25px;
    margin: 0 10px;
    background-image: url('data:image/svg+xml,<svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8595 0.580878L14.2537 2.18892L22.3476 10.2942L1.00522 10.2942L0.998463 12.5691L22.357 12.5717L14.2568 20.6832L15.86 22.2887L26.6985 11.435L15.8595 0.580878Z" fill="black"/></svg>');
    background-size: contain;
    &.disabled-page, &:hover{
      opacity: .5;
    } 
    a{
      &::after{
        content: '';
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .previous{
    transform: rotate(180deg);
  }
  
  .pagination {
    align-items: center;
    //background-color: #0fbcf9;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
    position: relative;
    //width: 1000px;
  }
  
  .pagination-page {
    font-weight: 700;
  }
  
  // .previous {
  //   border-right: solid 1px #808e9b;
  //   font-size: 4px;
  //   height: 60px;
  //   left: 0;
  //   position: absolute;
  //   width: 150px;
  // }