.session{

    &-inner{
        min-height: 14rem;
        overflow-wrap: break-word;                
    }

    &-status{
        text-transform: capitalize;
        font-weight: 600;
    }   
}

.user-inner--hover, .session-inner--hover, .organisation-inner--hover{
    transition: background-color ease-in-out .3s;
    cursor: pointer;
    &:hover{
        transition: background-color ease-in-out .3s;
        background-color: #AFB3F4;
        h2,h3,h4{
            text-decoration: underline;
        }
    }
}

.session-sidebar-link{
    cursor: pointer;
    &.session-sidebar-link--active{
        pointer-events: none;
        background-color: #AFB3F4;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 5px;
            background-color: #0E1031;
        }
    }
}

.video-container{
    aspect-ratio: 16 / 9;
    position: relative;
    .link{
        position: absolute;
        cursor: pointer;
        top:0;
        left:0;
        right:0;
        bottom:0;
    }
}
.video-container-container{
    transition: all .5s ease-in-out;
}

.link-cursor{
    cursor: pointer;
}

.session-row{
    
    .btn{
     visibility: hidden;       
     //top:5px;
    }
    .edit-btns{
        height: 100%;
        width: 100px;
        position: absolute;
        top:0;
        right: 0;
    }

    &::before{
        content: '';
        z-index: -1;
        position: absolute;
        top:0;
        bottom:0;
        left:.75rem;
        right:.75rem;
    }


    &:hover{
        &::before{
            background-color: #AFB3F4;
        }
        .btn{
            visibility: visible;
        }
    }
}

.btn-session-top{
    background-color: #FFEF00;
    width: 160px;
    border: none;

}

.btn-alt{
    background-color: #FFEF00;
    border: none;

}