// html{
//   font-size: 10px;
// }

h1,.h1{
    font-weight: normal;
    font-size: 2.5rem;

    @media only screen and (min-width: 768px) {
      font-size:  4.375rem;
    }

}

h2,.h2{
    font-size: 50px;
    font-size: 3.1rem;
    font-weight: 300;
  }

h3,.h3{
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 300;
  }

h4,.h4{
  font-size: 20px;
  font-size: 1.2rem;
  font-weight: 300;
  @media only screen and (min-width: 768px) {
    font-size: 25px;
    font-size: 1.525rem;
  }
}

h5,.h5{
  font-size: 20px;
  font-size: 2rem;
  font-weight: 400;
}

h6,.h6{
  font-size: 15px;
  font-size: 1.1rem;
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    font-size: 1.2rem;
  }
}



.font-size-0{
  font-size: 0;
}