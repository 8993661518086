.print-header{
    display: none;
}

@media print {

    .no-print, .no-print *{ display: none !important; }

    .print-content {
      display: block !important;
      font-size: 14px;
      width: 100%;
      height: 100%;
      page-break-after: always;
      overflow: visible;
    }

    .navbar{
        height: 0 !important;
        visibility: hidden;
    }

    .print-padding{
        padding: 0 !important;
        &.py-5{
            margin: 0 !important;
        }
    }
    .print-header{
        display: flex;
    }
  }