.dashboard{
    background-color: transparent;
    min-height: 30vh;
    h2{
        font-size: 4rem;
    }
    @media only screen and (min-width: 768px) {
        min-height: 50vh;
    }
}

.dashboard-session-inner {
    text-transform: capitalize;
    color: black;
    min-height: 6rem;
}
.dashboard-session-inner--hover{
    transition: background-color ease-in-out .3s;
    cursor: pointer;
    &:hover{
        transition: background-color ease-in-out .3s;
        background-color: #AFB3F4;
        h2,h3,h4{
            text-decoration: underline;
        }
    }
}
    
.dashboard-bg{
    color:white;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        left:0;
        bottom: 0;
        width: 100vw;
        height: 150px;
        background-image: linear-gradient(transparent 0, #0E1031 80%, #0E1031 100%);
    }
}


.dashboard-h3{
    font-size: 2.1rem;
    @media only screen and (min-width: 992px) {
        font-size: 2.5rem;
        font-size: 2.5vw;
    }
    @media only screen and (min-width: 1600px) {
        font-size: 2.5rem;
    }
}



.other-services{
    background-color: rgba(black ,.3);
    border: yellow 15px solid;
    position: relative;
    border-bottom: none;
    &::after{
        content: '';
        display: block;
        position: absolute;
        top:100%;
        width: 0; 
        height: 0; 
        //border-top: 60px solid transparent;
        border-bottom: 60px solid transparent;
        
        border-left: 60px solid rgba(black ,.3);
    }
}


.yellow-vector{
    
    position: relative;
    background-color: rgba($color: #000000, $alpha: .3);
    margin-bottom: 2rem;
    .inner{
        position: relative;
        border: 1rem #FFEF00 solid;
        border-bottom: none;
        h3,h4{
            font-weight: 500;
        }
       
        a{
            text-decoration: underline;
            &:hover{
                color: #e0e0e0;
            }
        }
        .dark{
            color:#d0d0d0;
            
            a{
                color: #ededed;
                &:hover{
                    color: #ffffff;
                }
            }
        }
    }
    .close{
        position: absolute;
        top:0;
        right:0;
        cursor: pointer;
        background-image: url('data:image/svg+xml,<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="16.1036" y1="1.09623" x2="0.593845" y2="16.6059" stroke="white"/><line x1="0.593788" y1="0.389122" x2="16.1035" y2="15.8988" stroke="white"/></svg>');
        background-size: contain;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        text-indent: -999999px;
    }
    &::after{
        content: '';
        width: 100%;
        aspect-ratio: 4/3;
        position: absolute;
        top: 100%;

        left: 0;
        background-image: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 464 95" style="enable-background:new 0 0 464 95;" xml:space="preserve"><style type="text/css">.st0{stroke:%23000000;stroke-miterlimit:10;}.st1{fill-opacity:0.3;}.st2{fill:%23FFEF00;}</style><path class="st0" d="M-224.2-747"/><g><path class="st1" d="M111.9,0v66l65.8-66"/><path class="st2" d="M177.7,0l-65.8,66V0H0v12h99.9v83l20.5-20.6L182.7,12H464V0H177.7z"/></g></svg>');
        background-size: cover-x;
        background-repeat: no-repeat;
    }
}

.dashboard-carousel{
    background-color: #0E1031;

    h3{
        @media only screen and (min-width: 991px) {
            font-size: 1.8rem;
        }
        @media only screen and (min-width: 1224px) {
            font-size: 2.5rem;
        }
    }
    

    .carousel-control-prev-icon{
        background: url('data:image/svg+xml,<svg width="28" height="12" viewBox="0 0 28 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 6L10 11.7735L10 0.226498L0 6ZM9 7L28 7L28 5L9 5L9 7Z" fill="white"/></svg>') no-repeat center;
    } 
    .carousel-control-next-icon {
        background: url('data:image/svg+xml,<svg width="28" height="12" viewBox="0 0 28 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28 6L18 0.226499L18 11.7735L28 6ZM-8.74228e-08 7L19 7L19 5L8.74228e-08 5L-8.74228e-08 7Z" fill="white"/></svg>') no-repeat center;
    }
    
  
    img{
  
        object-fit: cover;
     
    }
}

.carousel-inner{
    //overflow: visible;
    padding: 0 1rem;
}

.dashboard-carousel_copy{
    font-size: 0.9rem;
    z-index: 5;

    @media only screen and (min-width: 768px) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    @media only screen and (min-width: 991px) {
        font-size: 0.75rem;
    }
    @media only screen and (min-width: 1224px) {
        font-size: 0.9rem;
    }
    
    a{
        color: #AFB3F4;
        &:hover{
            color: #ffffff;
        }
    }
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    //display: block;
    position: absolute;
    bottom: .75rem !important;
}

