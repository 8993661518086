.booking-item{

    //background:#dddddd;
    transition: background-color ease-in-out .3s;

    width: 205px;
   
    &.selected, &:hover{

        transition: background-color ease-in-out .3s;
        background-color: #AFB3F4;
    }
    &.booked{
        transition: background-color ease-in-out .3s;
        background-color:rgba( #FFEF00,.9);
    }
    &.open{
        cursor: pointer;
        background-color:rgba( #FFFFFF,.9);
    }
    &.closed{
        background-color:rgba( #999999,.5);
        color:rgba( #777777,.8);
        // span{
        //     filter: blur(.5px);
        // }
    }
}

.add-remove{
    cursor: pointer;
    &:hover{
        &::after{
            content: ' -/+';
        }
    }
}